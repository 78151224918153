import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Login = ({ onLoginSuccess, onClose }) => {
  // Added onClose prop
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    if (/\s/.test(value)) {
      setError("Username cannot contain spaces");
    } else {
      setError("");
    }
    setUsername(value.replace(/\s/g, "").toLowerCase());
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    if (/\s/.test(value)) {
      setError("Password cannot contain spaces");
    } else {
      setError("");
    }
    setPassword(value.replace(/\s/g, ""));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!username.trim() || !password.trim()) {
      setError("Username and Password cannot be empty or contain only spaces.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "/api/v1/login",
        new URLSearchParams({
          username: username,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      localStorage.setItem("access_token", response.data.access_token);
      onLoginSuccess(response.data.user_info);
    } catch (err) {
      setError(
        err.response?.data?.detail ||
          "Login failed. Please check your credentials.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      style={{ backdropFilter: "blur(8px)" }}
    >
      <div className="font-grotesk bg-gradient-to-t from-neutral-800 to-neutral-500 border border-neutral-700 shadow-2xl p-8 rounded-lg w-11/12 md:w-1/3 relative">
        <button
          onClick={onClose} // Changed from handleClose to onClose
          className="absolute top-4 right-4 text-neutral-800 text-2xl hover:text-neutral-600"
        >
          &times;
        </button>
        <h2 className="text-2xl text-neutral-100 font-semibold mb-6 text-center">
          Login to Your Account
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="username" className="block mb-2 text-neutral-300">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              className="w-full px-3 py-2 text-neutral-100 bg-neutral-600 rounded focus:outline-none focus:ring-1 focus:ring-slate-100"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="text-neutral-300 block mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="w-full px-3 py-2 text-neutral-100 bg-neutral-600 rounded focus:outline-none focus:ring-1 focus:ring-slate-300"
              required
            />
          </div>
          {error && <p className="text-red-300 mb-4">{error}</p>}
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-gradient-to-b from-slate-800 to-neutral-950 text-neutral-100 hover:bg-slate-700 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {loading ? "Logging in..." : "Submit"}
          </button>
        </form>
        <div className="mt-4 text-center">
          <Link
            to="/forgot-password"
            className="text-neutral-400 hover:text-neutral-600"
          >
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
