import React, { useState } from "react";

const QuestionSubmissionsList = ({ submissions, title, columns = 1 }) => (
  <div>
    <h5 className="text-sm font-medium text-neutral-200 mb-3">{title}</h5>
    <div className={`grid grid-cols-${columns} gap-2`}>
      {submissions.map((submission, idx) => (
        <div
          key={idx}
          className="p-3 bg-neutral-800/50 rounded-lg border border-neutral-700 hover:bg-neutral-800 transition-all duration-200"
        >
          <div className="flex justify-between items-center">
            <span className="text-white text-xs font-medium">
              {submission.username}
            </span>
            {submission.submitted_at && (
              <span className="text-xs text-neutral-400">
                {new Date(submission.submitted_at).toLocaleDateString()}
              </span>
            )}
          </div>
          {submission.answer && (
            <p className="text-xs text-neutral-300 mt-2 bg-neutral-900/50 p-2 rounded">
              {submission.answer}
            </p>
          )}
        </div>
      ))}
    </div>
  </div>
);

const StatCard = ({ title, value }) => (
  <div className="p-4 bg-neutral-900/50 rounded-lg border border-neutral-800 hover:border-neutral-700 transition-all duration-200">
    <p className="text-sm text-neutral-400 font-medium">{title}</p>
    <p className="text-xl text-white mt-1 font-semibold">{value}</p>
  </div>
);

const QuestionStats = ({
  questionStats,
  questionStatsLoading,
  questionStatsError,
}) => {
  const [showPending, setShowPending] = useState(false);

  if (questionStatsLoading) {
    return (
      <div className="bg-neutral-900/50 border border-neutral-800 p-6 rounded-lg animate-pulse">
        <h4 className="text-xl mb-4 text-neutral-200">
          Loading submissions...
        </h4>
      </div>
    );
  }

  if (questionStatsError) {
    return (
      <div className="bg-red-900/10 border border-red-900/50 p-6 rounded-lg">
        <h4 className="text-lg mb-4 text-red-400">Error Loading Submissions</h4>
        <p className="text-red-300">{questionStatsError}</p>
      </div>
    );
  }

  if (!questionStats) return null;

  return (
    <div className="bg-neutral-900 border border-neutral-800 p-6 rounded-lg">
      <h4 className="text-lg font-semibold mb-6 text-white">
        Vibe Check Submissions
      </h4>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <StatCard
            title="Total Active Users"
            value={questionStats.total_active_users}
          />
          <StatCard
            title="Submissions This Week"
            value={questionStats.submissions_this_week}
          />
          <StatCard
            title="Submission Rate"
            value={`${questionStats.submission_rate}%`}
          />
        </div>

        <div className="text-sm text-neutral-400 px-1">
          Week Range:{" "}
          {new Date(questionStats.week_range.start).toLocaleDateString()} -{" "}
          {new Date(questionStats.week_range.end).toLocaleDateString()}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className={showPending ? "col-span-1" : "md:col-span-2"}>
            <QuestionSubmissionsList
              title="Recent Submissions"
              submissions={questionStats.recent_submissions || []}
              columns={showPending ? 1 : 2}
            />
          </div>

          {showPending ? (
            <div>
              <div className="flex justify-between items-center mb-3">
                <h5 className="text-sm font-medium text-neutral-200">
                  Pending ({questionStats.pending_submissions?.length || 0})
                </h5>
                <button
                  onClick={() => setShowPending(false)}
                  className="text-sm text-blue-400 hover:text-blue-300 transition-colors duration-200"
                >
                  Hide
                </button>
              </div>
              <div className="space-y-2">
                {questionStats.pending_submissions?.map((submission, idx) => (
                  <div
                    key={idx}
                    className="p-3 bg-neutral-800/50 rounded-lg border border-neutral-700"
                  >
                    <span className="text-white text-xs font-medium">
                      {submission.username}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <button
              onClick={() => setShowPending(true)}
              className="text-sm text-blue-400 hover:text-blue-300 transition-colors duration-200 ml-auto"
            >
              Show Pending ({questionStats.pending_submissions?.length || 0})
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionStats;
