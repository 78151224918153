import React, { useState, useEffect } from "react";
import axios from "axios";

const Settings = ({ businessName }) => {
  // State variables for password change
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // State variables for profile update
  const [newUsername, setNewUsername] = useState("");
  const [newEmail, setNewEmail] = useState("");

  // Error and success state
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Helper function to parse error messages
  const parseErrorMessage = (error) => {
    if (typeof error === "string") return error;
    if (Array.isArray(error))
      return error.map((e) => e.msg || String(e)).join(", ");
    if (typeof error === "object") {
      if (error.msg) return error.msg;
      return Object.values(error).map(parseErrorMessage).join(", ");
    }
    return "An unknown error occurred";
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All fields are required");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New passwords don't match");
      return;
    }

    try {
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/change-password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSuccess(response.data.message || "Password changed successfully");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (err) {
      console.error("Error response:", err.response?.data);
      setError(
        parseErrorMessage(err.response?.data?.detail) ||
          "Failed to change password",
      );
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    const updatedFields = {};
    if (newUsername) updatedFields.new_username = newUsername;
    if (newEmail) updatedFields.new_email = newEmail;

    if (Object.keys(updatedFields).length === 0) {
      setError("No fields to update");
      return;
    }

    try {
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/update-profile`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSuccess(response.data.message || "Profile updated successfully");
      setNewUsername("");
      setNewEmail("");
    } catch (err) {
      console.error("Profile update error:", err.response?.data);
      setError(
        parseErrorMessage(err.response?.data?.detail) ||
          "Failed to update profile",
      );
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 mt-10">
      {/* Main container with subtle gradient background */}
      <div className="bg-neutral-300 backdrop-blur-xl border border-neutral-800/50 rounded-lg p-8 shadow-2xl">
        <h2 className="text-2xl font-serif font-semibold text-slate-800 mb-8">
          User Settings
        </h2>

        {/* Error and Success Messages with improved styling */}
        {error && (
          <div className="bg-red-500/10 backdrop-blur-sm border border-red-500/20 text-red-400 px-4 py-3 rounded-sm mb-6 flex items-center">
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              ></path>
            </svg>
            {error}
          </div>
        )}
        {success && (
          <div className="bg-emerald-500/10 backdrop-blur-sm border border-emerald-500/20 text-emerald-400 px-4 py-3 rounded-xl mb-6 flex items-center">
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              ></path>
            </svg>
            {success}
          </div>
        )}

        <div className="space-y-6">
          {/* Password Change Section */}
          <div className="bg-gradient-to-t font-serif from-slate-800 to-neutral-950 backdrop-blur-sm border border-neutral-800/50 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-white mb-6 flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-neutral-300"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
              Change Password
            </h3>

            <form onSubmit={handlePasswordChange} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-neutral-300 mb-2">
                  Current Password
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full bg-neutral-950 border border-neutral-800 rounded-lg px-4 py-2.5 
                    text-white placeholder-neutral-500
                    focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
                    transition-all duration-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-neutral-300 mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full bg-neutral-950 border border-neutral-800 rounded-lg px-4 py-2.5 
                    text-white placeholder-neutral-500
                    focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
                    transition-all duration-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-neutral-300 mb-2">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full bg-neutral-950 border border-neutral-800 rounded-lg px-4 py-2.5 
                    text-white placeholder-neutral-500
                    focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
                    transition-all duration-200"
                />
              </div>

              <button
                type="submit"
                className="mt-6 w-full bg-slate-300 hover:bg-blue-500 text-neutral-800 px-6 py-3 rounded-sm
                  font-medium transition-all duration-200 
                  focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:ring-offset-2 focus:ring-offset-neutral-900
                  active:scale-[0.98]"
              >
                Update Password
              </button>
            </form>
          </div>

          {/* Profile Update Section */}
          <div className="bg-gradient-to-t from-slate-800 to-neutral-950 backdrop-blur-sm font-serif border border-neutral-800/50 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-white mb-6 flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-neutral-300"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              Update Profile
            </h3>

            <form onSubmit={handleProfileUpdate} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-neutral-300 mb-2">
                  New Username
                </label>
                <input
                  type="text"
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                  className="w-full bg-neutral-950 border border-neutral-800 rounded-lg px-4 py-2.5 
                    text-white placeholder-neutral-500
                    focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
                    transition-all duration-200"
                  placeholder="Enter new username"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-neutral-300 mb-2">
                  New Email
                </label>
                <input
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  className="w-full bg-neutral-950 border border-neutral-800 rounded-lg px-4 py-2.5 
                    text-white placeholder-neutral-500
                    focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
                    transition-all duration-200"
                  placeholder="Enter new email"
                />
              </div>

              <button
                type="submit"
                className="mt-6 w-full bg-neutral-300 hover:bg-blue-500 text-neutral-800 px-6 py-3 rounded-sm
                  font-medium transition-all duration-200 
                  focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:ring-offset-2 focus:ring-offset-neutral-900
                  active:scale-[0.98]"
              >
                Update Profile
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
