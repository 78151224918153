import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../utils/apiConfig";
import { ChartBar, Users, Download, TrendingUp } from "lucide-react";

const StatBar = ({ label, percentage, color }) => (
  <div className="space-y-3">
    <div className="flex justify-between items-center">
      <span className="text-sm font-medium text-neutral-200">{label}</span>
      <span className="font-semibold text-white bg-neutral-800/50 px-2 py-1 rounded-md text-sm">
        {percentage}%
      </span>
    </div>
    <div className="w-full bg-neutral-800/50 rounded-full h-2.5 backdrop-blur-sm">
      <div
        className={`${color} h-2.5 rounded-full transition-all duration-500 shadow-lg`}
        style={{ width: `${percentage}%` }}
      />
    </div>
  </div>
);

const StatRow = ({ label, value }) => (
  <div className="flex justify-between items-center p-3 rounded-lg hover:bg-neutral-800/30 transition-colors duration-200">
    <span className="text-sm font-medium text-neutral-300">{label}</span>
    <span className="font-semibold text-white bg-neutral-800/50 px-2 py-1 rounded-md text-sm">
      {value}
    </span>
  </div>
);

const ENPSStats = ({ businessName }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const [statsResponse, userResponse] = await Promise.all([
          axios.get(`${API_URL}/api/v1/businesses/${businessName}/enps-stats`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${API_URL}/api/v1/users/me`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);
        setStats(statsResponse.data);
        setCurrentUser(userResponse.data);
      } catch (err) {
        setError("Failed to load ENPS statistics");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [businessName]);

  const handleExport = async () => {
    if (!currentUser?.is_admin) return;

    try {
      setExporting(true);
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `${API_URL}/api/v1/businesses/${businessName}/enps-export`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        },
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `enps_responses_${businessName}_${new Date().toISOString().split("T")[0]}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error("Export failed:", err);
      alert("Failed to export data. Please try again.");
    } finally {
      setExporting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-40 bg-neutral-900/50 rounded-xl border border-neutral-800/50 backdrop-blur-sm">
        <div className="text-neutral-400 flex items-center gap-2">
          <div
            className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
            style={{ animationDelay: "0ms" }}
          />
          <div
            className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
            style={{ animationDelay: "150ms" }}
          />
          <div
            className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
            style={{ animationDelay: "300ms" }}
          />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-500/10 border border-red-500/20 p-6 rounded-xl text-red-400 text-center backdrop-blur-sm">
        <p className="font-medium">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-neutral-900 to-neutral-900/50 border border-neutral-800/50 rounded-xl p-8 backdrop-blur-sm">
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-blue-500/10 rounded-lg">
            <TrendingUp className="h-5 w-5 text-blue-400" />
          </div>
          <h3 className="text-xl font-semibold bg-gradient-to-r from-white to-neutral-400 bg-clip-text text-transparent font-grotesk">
            Employee Net Promoter Score Statistics
          </h3>
        </div>
        {currentUser?.is_admin && (
          <button
            onClick={handleExport}
            disabled={exporting || stats.answered_reviews === 0}
            className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 disabled:from-neutral-700 disabled:to-neutral-800 text-white rounded-lg text-sm transition-all duration-200 shadow-lg shadow-blue-500/20"
          >
            <Download className="h-4 w-4" />
            {exporting ? "Exporting Data..." : "Export to CSV"}
          </button>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-neutral-800/20 p-8 rounded-xl border border-neutral-700/50 backdrop-blur-sm font-grotesk">
          <div className="flex items-center gap-3 mb-8">
            <div className="p-2 bg-neutral-700/30 rounded-lg">
              <ChartBar className="h-5 w-5 text-neutral-300" />
            </div>
            <div>
              <p className="text-sm text-neutral-400 font-medium">
                Current ENPS
              </p>
              <p className="text-xl font-bold bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">
                {stats.enps_score}
              </p>
            </div>
          </div>
          <div className="space-y-6">
            <StatBar
              label="Promoters (9-10)"
              percentage={stats.promoters_percentage}
              color="bg-gradient-to-r from-emerald-500 to-emerald-600"
            />
            <StatBar
              label="Passives (7-8)"
              percentage={stats.passives_percentage}
              color="bg-gradient-to-r from-yellow-500 to-yellow-600"
            />
            <StatBar
              label="Detractors (0-6)"
              percentage={stats.detractors_percentage}
              color="bg-gradient-to-r from-red-500 to-red-600"
            />
          </div>
        </div>

        <div className="bg-neutral-800/20 p-8 rounded-xl border border-neutral-700/50 backdrop-blur-sm font-grotesk">
          <div className="flex items-center gap-3 mb-8">
            <div className="p-2 bg-neutral-700/30 rounded-lg">
              <Users className="h-5 w-5 text-neutral-300" />
            </div>
            <div>
              <p className="text-sm text-neutral-400 font-medium">
                Response Overview
              </p>
              <p className="text-xl font-bold text-white">
                {stats.answered_reviews}{" "}
                <span className="text-sm text-neutral-400">
                  total responses
                </span>
              </p>
            </div>
          </div>
          <div className="space-y-1">
            <StatRow label="Total Responses" value={stats.answered_reviews} />
            <StatRow label="Promoters" value={stats.promoters} />
            <StatRow label="Passives" value={stats.passives} />
            <StatRow label="Detractors" value={stats.detractors} />
            <div className="my-4 border-t border-neutral-600/50" />
            <StatRow
              label="Recent Reviews (30 days)"
              value={stats.recent_reviews}
            />
            <StatRow label="Recent Responses" value={stats.recent_answered} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ENPSStats;
