import React, { useState, useEffect } from "react";
import axios from "axios";
import SentimentAnalysis from "./analytics/SentimentAnalysis";
import ScoreGrid from "./analytics/ScoreGrid";
import ReviewStats from "./analytics/ReviewStats";
import QuestionStats from "./analytics/QuestionStats";
import ENPSStats from "./ENPS/ENPSStats";
import { Activity, Users, Star, MessageCircle } from "lucide-react";

const StatCard = ({ title, value, icon: Icon, loading }) => (
  <div className="bg-neutral-900 rounded-lg p-4 border border-neutral-800 hover:bg-neutral-600/50 transition-colors duration-200">
    <div className="flex items-center justify-between mb-2 font-grotesk">
      <h3 className="text-sm font-medium text-neutral-200">{title}</h3>
      <Icon className="w-4 h-4 text-neutral-400" />
    </div>
    {loading ? (
      <div className="h-7 w-1/2 bg-neutral-800 animate-pulse rounded" />
    ) : (
      <div className="text-2xl font-bold text-white">{value}</div>
    )}
  </div>
);

const Analytics = ({ businessName }) => {
  // Analytics states
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  // Average Score states
  const [averageScore, setAverageScore] = useState(null);
  const [lastCalculated, setLastCalculated] = useState(null);
  const [averageScoreError, setAverageScoreError] = useState(null);

  // Review stats states
  const [reviewStats, setReviewStats] = useState(null);
  const [reviewStatsLoading, setReviewStatsLoading] = useState(true);
  const [reviewStatsError, setReviewStatsError] = useState(null);

  // Question stats states
  const [questionStats, setQuestionStats] = useState(null);
  const [questionStatsLoading, setQuestionStatsLoading] = useState(true);
  const [questionStatsError, setQuestionStatsError] = useState(null);

  // Quarter selection state (lifted up from ReviewStats)
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    fetchUser();
    fetchAnalytics();
    fetchAverageScore();
    fetchReviewStats();
    fetchQuestionStats();
  }, [businessName]);

  // Set initial quarter and year
  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;
    setSelectedYear(currentYear);
    setSelectedQuarter(currentQuarter);
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`/api/v1/users/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setUser(response.data);
    } catch (err) {
      console.error("Error fetching user:", err);
    }
  };

  const fetchAnalytics = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/analytics`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setAnalytics(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching analytics:", err);
      setError("Failed to fetch analytics. Please try again.");
      setLoading(false);
    }
  };

  const fetchAverageScore = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/average-vc-score`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setAverageScore(response.data.average_score);
      setLastCalculated(response.data.last_calculated);
    } catch (err) {
      console.error("Error fetching average score:", err);
      setAverageScoreError("Failed to fetch average score. Please try again.");
    }
  };

  const fetchReviewStats = async () => {
    setReviewStatsLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/review-stats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setReviewStats(response.data);
      setReviewStatsLoading(false);
    } catch (err) {
      console.error("Error fetching review stats:", err);
      setReviewStatsError("Failed to fetch review statistics.");
      setReviewStatsLoading(false);
    }
  };

  const fetchQuestionStats = async () => {
    setQuestionStatsLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/question-stats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setQuestionStats(response.data);
      setQuestionStatsLoading(false);
    } catch (err) {
      console.error("Error fetching question stats:", err);
      setQuestionStatsError("Failed to fetch question statistics.");
      setQuestionStatsLoading(false);
    }
  };

  const getTotalReviews = () => {
    if (!reviewStats?.submissions_data || !selectedQuarter || !selectedYear)
      return "-";

    return reviewStats.submissions_data.reduce((total, review) => {
      if (review.recent_submissions && review.recent_submissions.length > 0) {
        const submissionDate = new Date(
          review.recent_submissions[0].submitted_at,
        );
        const year = submissionDate.getFullYear();
        const quarter = Math.floor(submissionDate.getMonth() / 3) + 1;

        if (year === selectedYear && quarter === selectedQuarter) {
          return total + review.submissions_this_week;
        }
      }
      return total;
    }, 0);
  };

  if (loading) return <div>Loading analytics...</div>;
  if (error) return <div className="text-red-300">{error}</div>;

  return (
    <div className="min-h-screen bg-neutral-800 text-neutral-100 p-8 border border-neutral-700 rounded mt-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-2xl font-bold font-grotesk mb-8 bg-gradient-to-r from-neutral-100 to-zinc-300 bg-clip-text text-transparent">
          Analytics Dashboard
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-3 font-grotesk">
          <StatCard
            title="Average Score"
            value={averageScore?.toFixed(2) || "-"}
            icon={Activity}
            loading={loading}
          />
          <StatCard
            title="Total Reviews"
            value={getTotalReviews()}
            icon={MessageCircle}
            loading={reviewStatsLoading}
          />
          <StatCard
            title="Active Users"
            value={analytics?.active_users || "-"}
            icon={Users}
            loading={loading}
          />
          <StatCard
            title="Satisfaction Rate"
            value={
              analytics?.satisfaction_rate
                ? `${analytics.satisfaction_rate}%`
                : "-"
            }
            icon={Star}
            loading={loading}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-3">
          <div className="bg-neutral-900 rounded-lg border border-neutral-700">
            <ENPSStats businessName={businessName} />
          </div>

          <div className="bg-neutral-900 rounded-lg p-4 border border-neutral-700">
            <h2 className="text-lg font-semibold mb-4 font-grotesk">
              Score Distribution
            </h2>
            <ScoreGrid
              analytics={analytics}
              averageScore={averageScore}
              lastCalculated={lastCalculated}
              selectedQuarter={selectedQuarter}
              setSelectedQuarter={setSelectedQuarter}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              reviewStats={reviewStats}
              reviewStatsLoading={reviewStatsLoading}
            />
          </div>
        </div>

        <div className="space-y-3">
          <div className="bg-neutral-900 rounded-lg p-3 border border-neutral-700">
            <h2 className="text-lg font-semibold mb-4 font-grotesk">
              Question Analysis
            </h2>
            <QuestionStats
              questionStats={questionStats}
              questionStatsLoading={questionStatsLoading}
              questionStatsError={questionStatsError}
            />
          </div>

          <div className="bg-neutral-900 rounded-lg p-6 border border-neutral-700">
            <ReviewStats
              reviewStats={reviewStats}
              reviewStatsLoading={reviewStatsLoading}
              reviewStatsError={reviewStatsError}
              selectedQuarter={selectedQuarter}
              setSelectedQuarter={setSelectedQuarter}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </div>
        </div>

        {user?.is_admin && (
          <div className="bg-neutral-900 rounded-lg p-6 border border-neutral-800 mt-6">
            <h2 className="text-lg font-semibold mb-4">Sentiment Analysis</h2>
            <SentimentAnalysis businessName={businessName} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Analytics;
