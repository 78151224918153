import React, { useState, useMemo, useEffect } from "react";
import { Calendar, Clock, ChevronRight } from "lucide-react";

const ResponseDateSelector = ({
  sortedDates,
  selectedDate,
  handleDateClick,
  groupedResponses,
}) => {
  // First, ensure dates are sorted with most recent first
  const orderedDates = useMemo(() => {
    return [...sortedDates].sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB - dateA; // Most recent first
    });
  }, [sortedDates]);

  // Calculate quarters based on ordered dates
  const quarterOptions = useMemo(() => {
    const totalWeeks = orderedDates.length;
    const quarterCount = Math.ceil(totalWeeks / 12);

    return Array.from({ length: quarterCount }, (_, i) => {
      const startIndex = i * 12;
      const endIndex = Math.min(startIndex + 12, totalWeeks);

      const startDate = new Date(orderedDates[startIndex]);
      const endDate = new Date(orderedDates[endIndex - 1]);

      return {
        label: `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
        startIndex,
        endIndex,
      };
    });
  }, [orderedDates]);

  const [currentQuarterIndex, setCurrentQuarterIndex] = useState(0);

  // Filter visible dates based on selected quarter
  const visibleDates = useMemo(() => {
    const quarter = quarterOptions[currentQuarterIndex];
    if (!quarter) return orderedDates;
    return orderedDates.slice(quarter.startIndex, quarter.endIndex);
  }, [orderedDates, currentQuarterIndex, quarterOptions]);

  // Add debugging logs
  console.log("Original dates:", sortedDates);
  console.log("Ordered dates:", orderedDates);
  console.log("Quarter options:", quarterOptions);
  console.log("Visible dates:", visibleDates);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString
      .split("-")
      .map((num) => parseInt(num, 10));
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString();
  };

  return (
    <div className="w-full">
      <div className="border bg-gradient-to-t from-slate-800 to-neutral-950 border-neutral-500 rounded-lg shadow-lg">
        <div className="p-2 bg-gradient-to-b from-slate-800 to bg-neutral-950 rounded-t border-b border-neutral-200">
          <div className="flex flex-col space-y-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="h-[2px] w-4 bg-gradient-to-r from-neutral-400 to-transparent" />
                <div className="flex items-center space-x-2">
                  <Calendar
                    className="w-4 h-4 text-neutral-200"
                    strokeWidth={1.5}
                  />
                  <span className="text-slate-200 text-xs uppercase tracking-wider">
                    Select Date-Range
                  </span>
                </div>
              </div>
              <ChevronRight
                className="w-4 h-4 text-neutral-200"
                strokeWidth={1.5}
              />
            </div>

            {/* Quarter selector dropdown */}
            <div className="w-full">
              <select
                value={currentQuarterIndex}
                onChange={(e) => setCurrentQuarterIndex(Number(e.target.value))}
                className="w-full bg-neutral-800 text-neutral-200 text-xs px-3 py-1.5 rounded-md border border-neutral-600 
                         hover:border-neutral-500 focus:outline-none focus:ring-2 focus:ring-blue-500/40"
              >
                {quarterOptions.map((quarter, index) => (
                  <option key={index} value={index}>
                    {quarter.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="p-4">
          {/* Mobile View: Horizontal scrollable container */}
          <div className="block lg:hidden">
            <div className="overflow-x-auto no-scrollbar pb-2">
              <div className="flex space-x-3 min-w-min">
                {visibleDates.map((date) => (
                  <DateButton
                    key={date}
                    date={date}
                    isSelected={selectedDate === date}
                    responseCount={groupedResponses[date].length}
                    formatDate={formatDate}
                    handleDateClick={handleDateClick}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Desktop View: Vertical layout */}
          <div className="hidden lg:block">
            <div className="flex flex-col space-y-3">
              {visibleDates.map((date) => (
                <DateButton
                  key={date}
                  date={date}
                  isSelected={selectedDate === date}
                  responseCount={groupedResponses[date].length}
                  formatDate={formatDate}
                  handleDateClick={handleDateClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DateButton = ({
  date,
  isSelected,
  responseCount,
  formatDate,
  handleDateClick,
}) => (
  <button
    onClick={() => handleDateClick(date)}
    className={`
      flex-none transition-all duration-300 w-48 lg:w-full
      ${
        isSelected
          ? "bg-slate-800 border border-slate-700 rounded-lg"
          : "bg-slate-600 border border-slate-500 rounded-lg hover:border-neutral-100"
      }
    `}
  >
    <div className="p-3">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <Clock className="w-4 h-4 text-neutral-100" strokeWidth={1.5} />
          <span className="text-neutral-100 text-xs">{formatDate(date)}</span>
        </div>
        <div className="text-xs text-neutral-100">
          {responseCount} {responseCount === 1 ? "response" : "responses"}
        </div>
      </div>
    </div>
  </button>
);

export default ResponseDateSelector;
