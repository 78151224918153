import React, { useState, useEffect } from "react";
import ReviewSubmissionsLists from "./ReviewSubmissionLists";
import { ChevronDown, ChevronRight } from "lucide-react";

const ReviewStats = ({
  reviewStats,
  reviewStatsLoading,
  reviewStatsError,
  selectedQuarter,
  setSelectedQuarter,
  selectedYear,
  setSelectedYear,
}) => {
  const [selectedReview, setSelectedReview] = useState(null);
  const [organizedReviews, setOrganizedReviews] = useState({});

  useEffect(() => {
    if (reviewStats?.submissions_data) {
      const reviews = {};

      reviewStats.submissions_data.forEach((review) => {
        const submissionDate = new Date(
          review.recent_submissions[0]?.submitted_at || Date.now(),
        );
        const year = submissionDate.getFullYear();
        const quarter = Math.floor(submissionDate.getMonth() / 3) + 1;

        if (!reviews[year]) reviews[year] = {};
        if (!reviews[year][quarter]) reviews[year][quarter] = [];
        reviews[year][quarter].push(review);
      });

      setOrganizedReviews(reviews);
    }
  }, [reviewStats]);

  if (reviewStatsLoading)
    return (
      <div className="mt-6 bg-neutral-800 p-4">
        <p className="text-neutral-400">Loading review statistics...</p>
      </div>
    );
  if (reviewStatsError)
    return (
      <div className="mt-6 bg-neutral-800 p-4">
        <p className="text-red-500">{reviewStatsError}</p>
      </div>
    );
  if (!reviewStats?.submissions_data) return null;

  const filterPendingSubmissionsByTeam = (reviewData) => {
    if (reviewData.is_team_review && reviewData.team_members) {
      return reviewData.pending_submissions.filter((submission) =>
        reviewData.team_members.includes(submission.user_id),
      );
    }
    return reviewData.pending_submissions;
  };

  const years = Object.keys(organizedReviews).sort((a, b) => b - a);
  const getQuarterLabel = (q) =>
    `Q${q} (${["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"][q - 1]})`;

  return (
    <div className="bg-gradient-to-b from-neutral-900 to-neutral-950 border border-neutral-800 p-2 rounded font-grotesk">
      <div className="flex items-center justify-between mb-6">
        <h4 className="text-lg font-grotesk text-neutral-100 tracking-wider">
          Review Submissions
        </h4>
        <div className="flex gap-4">
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            className="bg-neutral-800 text-neutral-200 border border-neutral-700 rounded px-3 py-1"
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <select
            value={selectedQuarter}
            onChange={(e) => setSelectedQuarter(Number(e.target.value))}
            className="bg-neutral-800 text-neutral-200 border border-neutral-700 rounded px-3 py-1"
          >
            {[1, 2, 3, 4].map((q) => (
              <option key={q} value={q}>
                {getQuarterLabel(q)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="space-y-4">
        {organizedReviews[selectedYear]?.[selectedQuarter]?.map(
          (reviewData, index) => (
            <div
              key={index}
              className="border border-neutral-700 rounded overflow-hidden"
            >
              <button
                onClick={() =>
                  setSelectedReview(selectedReview === index ? null : index)
                }
                className="w-full p-4 text-left bg-neutral-800 hover:bg-neutral-700 transition-colors duration-150"
              >
                <h5 className="text-sm text-neutral-200 flex items-center justify-between">
                  {reviewData.review_text}
                  {selectedReview === index ? (
                    <ChevronDown className="h-4 w-4 text-neutral-400" />
                  ) : (
                    <ChevronRight className="h-4 w-4 text-neutral-400" />
                  )}
                </h5>
              </button>

              {selectedReview === index && (
                <div className="p-4 space-y-6 bg-neutral-900">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="p-4 bg-neutral-800 border border-neutral-700 rounded-lg">
                      <p className="text-sm text-neutral-400 mb-1">
                        Total Active Users
                      </p>
                      <p className="text-xl text-neutral-100">
                        {reviewData.total_active_users}
                      </p>
                    </div>
                    <div className="p-4 bg-neutral-800 border border-neutral-700 rounded-lg">
                      <p className="text-sm text-neutral-400 mb-1">
                        Submissions
                      </p>
                      <p className="text-xl text-neutral-100">
                        {reviewData.submissions_this_week}
                      </p>
                    </div>
                    <div className="p-4 bg-neutral-800 border border-neutral-700 rounded-lg">
                      <p className="text-sm text-neutral-400 mb-1">
                        Submission Rate
                      </p>
                      <p className="text-xl text-neutral-100">
                        {reviewData.submission_rate}%
                      </p>
                    </div>
                  </div>

                  <ReviewSubmissionsLists
                    recentSubmissions={reviewData.recent_submissions}
                    pendingSubmissions={filterPendingSubmissionsByTeam(
                      reviewData,
                    )}
                  />
                </div>
              )}
            </div>
          ),
        )}

        {(!organizedReviews[selectedYear]?.[selectedQuarter] ||
          organizedReviews[selectedYear][selectedQuarter].length === 0) && (
          <div className="text-center py-8 text-neutral-400">
            No reviews found for {getQuarterLabel(selectedQuarter)}{" "}
            {selectedYear}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewStats;
