import React, { useState, useEffect } from "react";
import { ArrowRight, Sparkles } from "lucide-react";
const Login = React.lazy(() => import("../../components/Login.jsx"));
const Features = React.lazy(() => import("./Features.jsx"));
const HeroSection = React.lazy(() => import("./HeroSection.jsx"));
const AnimatedBackground = React.lazy(() => import("./AnimatedBackground.jsx"));
const AboutSection = React.lazy(
  () => import("../../Pages/LandingPage/AboutSection.jsx"),
);
const ContactSection = React.lazy(() => import("./ContactSection.jsx"));
const Footer = React.lazy(() => import("./Footer.jsx"));

const LandingPage = ({ onLoginSuccess }) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) =>
      setMousePosition({ x: e.clientX, y: e.clientY });
    const handleScroll = () => setScrollPosition(window.scrollY);

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const Header = () => (
    <header className="fixed w-full top-0 z-50 backdrop-blur-md bg-neutral-900/80 border-b border-neutral-800">
      <nav className="container mx-auto px-4 flex justify-between items-center h-16 md:h-20">
        <div className="flex items-center">
          <h1 className="font-grotesk text-2xl md:text-4xl font-bold bg-gradient-to-r from-emerald-600 to-cyan-500 bg-clip-text text-transparent">
            matcha
          </h1>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-6">
          {["About", "Contact"].map((item, index) => (
            <a
              key={item}
              href={`/${item.toLowerCase()}`}
              className="relative text-neutral-300 hover:text-emerald-400 transition-colors group font-grotesk"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <span className="relative z-10">{item}</span>
              <span className="absolute inset-0 bg-emerald-900/0 group-hover:bg-emerald-900/20 -z-10 rounded-md transform scale-[1.1] opacity-0 group-hover:opacity-100 transition-all duration-300" />
            </a>
          ))}
          <button
            onClick={() => setShowLoginForm(true)}
            className="px-6 py-2 bg-neutral-300 text-neutral-800 rounded-lg relative group overflow-hidden font-grotesk"
          >
            <span className="relative z-10">Login</span>
            <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-cyan-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </button>
        </div>

        {/* Mobile Navigation */}
        <div className="md:hidden flex items-center space-x-4">
          <button
            onClick={() => setShowLoginForm(true)}
            className="px-4 py-1.5 bg-emerald-600 text-neutral-100 text-sm rounded-lg relative group overflow-hidden font-grotesk"
          >
            <span className="relative z-10">Login</span>
            <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-cyan-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden absolute top-full left-0 right-0 bg-neutral-900 border-t border-neutral-800">
          <div className="py-4 px-4 space-y-4">
            {["About", "Contact"].map((item) => (
              <a
                key={item}
                href={`/${item.toLowerCase()}`}
                className="block text-neutral-300 hover:text-emerald-400 font-grotesk"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {item}
              </a>
            ))}
          </div>
        </div>
      )}
    </header>
  );

  return (
    <div className="min-h-screen overflow-hidden bg-neutral-900 text-neutral-100">
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center min-h-screen">
            <div className="text-neutral-400">Loading...</div>
          </div>
        }
      >
        <AnimatedBackground mousePosition={mousePosition} />
        <Header />
        <main className="pt-16 md:pt-20">
          <HeroSection scrollPosition={scrollPosition} />
          <Features />
          <div id="about">
            <AboutSection />
          </div>
          <ContactSection />
          <Footer />
        </main>

        {/* Floating cursor effect - hidden on mobile */}
        <div
          className="fixed w-4 h-4 border-2 border-emerald-400 rounded-full pointer-events-none mix-blend-difference z-50 hidden md:block"
          style={{
            left: mousePosition.x - 8,
            top: mousePosition.y - 8,
            transition: "all 0.15s ease",
            boxShadow: "0 0 20px rgba(52, 211, 153, 0.3)",
          }}
        />

        {/* Login Modal */}
        {showLoginForm && (
          <Login
            onLoginSuccess={onLoginSuccess}
            onClose={() => setShowLoginForm(false)}
          />
        )}
      </React.Suspense>
    </div>
  );
};

export default LandingPage;
