import React from "react";

const ScoreCard = ({ title, score, lastUpdated }) => (
  <div className="bg-neutral-900/50 border border-neutral-800 p-4 rounded-lg hover:bg-neutral-900 transition-all duration-200">
    <h4 className="text-sm text-neutral-400 font-medium mb-2 font-grotesk">
      {title}
    </h4>
    <p className="text-xl font-bold text-white font-grotesk">
      {score !== null ? score.toFixed(2) : "N/A"}
    </p>
    {lastUpdated && (
      <p className="text-xs text-neutral-500 mt-2">
        Last updated: {new Date(lastUpdated).toLocaleDateString()}
      </p>
    )}
  </div>
);

const ScoreGrid = ({
  analytics,
  averageScore,
  lastCalculated,
  selectedQuarter,
  selectedYear,
  reviewStats,
  reviewStatsLoading,
}) => {
  const getQuarterlyReviewRate = () => {
    if (!reviewStats?.submissions_data || !selectedQuarter || !selectedYear)
      return 0;
    const quarterData = reviewStats.submissions_data.reduce((acc, review) => {
      if (review.recent_submissions && review.recent_submissions.length > 0) {
        const submissionDate = new Date(
          review.recent_submissions[0].submitted_at,
        );
        const year = submissionDate.getFullYear();
        const quarter = Math.floor(submissionDate.getMonth() / 3) + 1;
        if (year === selectedYear && quarter === selectedQuarter) {
          return acc + review.submission_rate;
        }
      }
      return acc;
    }, 0);
    return quarterData;
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <ScoreCard title="Yearly eNPS Score" score={analytics?.enps_score} />
      <ScoreCard
        title="Vibe Check Average"
        score={averageScore}
        lastUpdated={lastCalculated}
      />
      <ScoreCard
        title="Perfect Vibes This Week"
        score={analytics?.perfect_scores || 0}
      />
      <ScoreCard
        title="Bad Vibes This Week"
        score={analytics?.bad_scores || 0}
      />
    </div>
  );
};

export default ScoreGrid;
