import React, { useState, useEffect } from "react";
import axios from "axios";
import QuestionOrder from "./QuestionOrder";

const QuestionList = ({ businessName }) => {
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [newQuestion, setNewQuestion] = useState("");
  const [activeOrderTab, setActiveOrderTab] = useState(1); // Changed to numeric ID

  useEffect(() => {
    fetchQuestions();
  }, [businessName]);

  const fetchQuestions = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/questions`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setQuestions(response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching questions:", err);
      setError("Failed to fetch questions. Please try again.");
    }
  };

  const handleAddQuestion = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/questions`,
        { question_text: newQuestion },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setQuestions((prevQuestions) =>
        [...prevQuestions, response.data].sort((a, b) => a.order - b.order),
      );
      setNewQuestion("");
      window.dispatchEvent(new Event("questionsUpdated"));
    } catch (err) {
      console.error("Error adding question:", err);
      if (err.response && err.response.data && err.response.data.detail) {
        setError(`Failed to add question: ${err.response.data.detail}`);
      } else {
        setError("Failed to add question. Please try again.");
      }
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(
        `/api/v1/businesses/${businessName}/questions/${questionId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setQuestions(questions.filter((q) => q.id !== questionId));
      window.dispatchEvent(new Event("questionsUpdated"));
    } catch (err) {
      console.error("Error deleting question:", err);
      setError("Failed to delete question. Please try again.");
    }
  };

  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="font-serif bg-neutral-400 p-8 mt-6 shadow-2xl">
      <h3 className="text-lg text-slate-200 mb-8 text-center bg-gradient-to-t from-slate-800 to-neutral-950 p-1">
        Vibe Check Questions
      </h3>
      <form onSubmit={handleAddQuestion} className="mb-8">
        <input
          type="text"
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
          placeholder="Enter new question"
          className="w-full p-2 bg-gray-700 rounded text-white"
          required
        />
        <button
          type="submit"
          className="mt-4 px-4 py-2 bg-slate-800 text-white rounded hover:bg-indigo-700"
        >
          Add Question
        </button>
      </form>

      <div className="flex bg-neutral-600 p-3 rounded">
        <button
          className={`px-4 py-2 mr-2 rounded text-white ${
            activeOrderTab === 1 ? "bg-slate-600" : "bg-slate-800"
          }`}
          onClick={() => setActiveOrderTab(1)}
        >
          1st Question Set
        </button>
        <button
          className={`px-4 py-2 rounded text-white ${
            activeOrderTab === 2 ? "bg-slate-600" : "bg-slate-800"
          }`}
          onClick={() => setActiveOrderTab(2)}
        >
          2nd Question Set
        </button>
      </div>

      <QuestionOrder
        businessName={businessName}
        orderId={activeOrderTab}
        onDeleteQuestion={handleDeleteQuestion}
      />
    </div>
  );
};

export default QuestionList;
